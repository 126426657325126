import admin from './admin'
import campaigns from './campaigns'
import clients from './clients'
import contacts from './contacts'
import deals from './deals'
import recommendation from './recommendation'

export default {
  admin,
  campaigns,
  clients,
  contacts,
  deals,
  recommendation
}
